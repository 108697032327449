/* eslint-disable no-param-reassign */
import React, { Fragment, useState, useContext, useMemo } from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import RequestModal from 'views/RequestsPage/components/RequestModal';
import RequestsContext, { RequestsContextWrapper } from 'views/RequestsPage/context';
import MainLayout from 'components/MainLayout';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Pagination from 'components/Pagination';
import PopMenu from 'components/PopMenu';
import { Icon } from '@xchange/uikit';
import BuyProcess from 'views/BuyProcess';
import SellProcess from 'views/SellProcess';
import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import Notification from './Notification';
import UsersList from './UsersList';
import Context, { NotificationsContextProvider } from './context';
import { getRequestByNotificationId } from './api';

type NotificationPopupProps = {
  target: Element;
  data: NotificationPageItem;
};

const modifiers = [{ name: 'offset', options: { offset: [0, 21] } }];

const NotificationsPage = () => {
  const {
    notifications,
    filter,
    setFilter,
    filterOptions,
    totalPages,
    handleReassignUser,
    buyProcessId,
    setBuyProcessId,
    listing,
    setListing,
    fetchListing,
    currentPage,
    setCurrentPage
  } = useContext(Context);
  const { selectedRequest, setSelectedRequest } = useContext(RequestsContext);
  const [notificationPopup, setNotificationPopup] = useState<NotificationPopupProps | undefined>();
  const [sellProcessData, setSellProcessData] = useState<{
    listingId: string;
    sellProcessId?: string;
  }>();

  const filterText = useMemo(
    () =>
      !filter.length || filter.length === filterOptions.length
        ? 'All'
        : filter.map(item => filterOptions.find(option => option.value === item)?.text).join(', '),
    [filter, filterOptions]
  );

  const groupNotifications = useMemo(
    () =>
      notifications?.reduce((accum, current) => {
        const date = dayjs(current.date * 1000).format('MM/DD/YYYY');
        accum[date] = accum[date] ? [...accum[date], current] : [current];
        return accum;
      }, {}),
    [notifications]
  );

  const handleRequestViewClick = async (value: NotificationPageItem) => {
    try {
      const request = await getRequestByNotificationId(value.id);
      setSelectedRequest(request);
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  };

  const handleCheckOfferClick = (data: NotificationPageItem) => {
    fetchListing(data.listingId!);
    setBuyProcessId(data.formData.buyProcessId);
  };

  const handleCloseOfferForm = () => {
    setBuyProcessId(undefined);
    setListing(undefined);
  };

  const getNotificationsByDate = date => {
    return (
      <Fragment key={date}>
        <div className="notifications__date">{date}</div>
        <div className="notifications__list">
          {groupNotifications?.[date].map(item => (
            <Notification
              key={item.id}
              data={item}
              onRequestViewClick={handleRequestViewClick}
              onReassignClick={e =>
                setNotificationPopup({
                  target: e.target as HTMLButtonElement,
                  data: item
                })
              }
              onEditOfferClick={handleCheckOfferClick}
              onClickListingAgreement={() => setSellProcessData({
                listingId: item.listingId,
                sellProcessId: item.formData.sellProcessId
              })}
            />
          ))}
        </div>
      </Fragment>
    );
  };

  const assignedUser = useMemo(() => {
    if (!notificationPopup?.data) return null;
    const { reassignedTo, createdFor, createdBy, type } = notificationPopup.data;
    if (reassignedTo) return reassignedTo;
    if (type === 'createListing') return createdFor || createdBy;

    return createdFor;
  }, [notificationPopup]);

  return (
    <MainLayout>
      <RequestModal open={Boolean(selectedRequest)} onClose={() => setSelectedRequest(undefined)} />
      <StyledNotificationsPage className="notifications">
        <div className="notifications__header">
          <h4 className="notifications__title">Notifications</h4>
          <div className="notifications__controls">
            <StyledDropdown
              options={filterOptions}
              value={filter}
              onChange={setFilter}
              label="Show:"
              text={filterText}
              multiple
              optionAll
              buttonLike
            />
          </div>
        </div>
        <div className="notifications__content">
          {groupNotifications && Object.keys(groupNotifications).map(getNotificationsByDate)}
        </div>
        {notifications && notifications.length > 0 ? (
          totalPages > 1 && (
            <Pagination page={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
          )
        ) : (
            <div className="empty-list">No notifications found.</div>
          )}
        <StyledPopMenu
          onOutsideClick={() => setNotificationPopup(undefined)}
          open={Boolean(notificationPopup?.target)}
          target={notificationPopup?.target}
          modifiers={modifiers}
          className="assign-user">
          <div className="assign-user__header">
            <h3>Reassign to</h3>
            <Button onClick={() => setNotificationPopup(undefined)}>
              <Icon name="mini-x" />
            </Button>
          </div>
          <UsersList
            assigned={assignedUser}
            onAssignClick={userId => {
              handleReassignUser(userId, notificationPopup!.data);
              setNotificationPopup(undefined);
            }}
          />
        </StyledPopMenu>
        {listing && (
          <BuyProcess
            open={Boolean(buyProcessId)}
            listing={listing}
            buyProcessId={buyProcessId}
            onClose={handleCloseOfferForm}
            onSave={handleCloseOfferForm}
          />
        )}
        {sellProcessData && (
          <SellProcess
            open={Boolean(sellProcessData)}
            onClose={() => setSellProcessData(undefined)}
            onSuccess={() => setSellProcessData(undefined)}
            {...sellProcessData!}
          />
        )}
      </StyledNotificationsPage>
    </MainLayout>
  );
};

export default () => (
  <NotificationsContextProvider>
    <RequestsContextWrapper isNotificationsPage>
      <NotificationsPage />
    </RequestsContextWrapper>
  </NotificationsContextProvider>
);

const StyledNotificationsPage = styled.div`
  .notifications {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      margin: 16px 0;

      h4 {
        margin: 0;
      }
    }
    &__date {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
    }
    &__list {
      margin: 10px 0 10px;
    }
  }
  &__list {
    margin: 10px 0 0;
  }
  .pagination {
    width: 100%;
    text-align: center;
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: auto;
  max-width: 300px;
  .dropdown-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dropdown-options {
    width: auto;
    right: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 220px;
  }
`;

const StyledPopMenu = styled(PopMenu)`
  width: 324px;

  .assign-user {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4px;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .button {
        height: 24px;
        padding: 0 6px;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;
