/* eslint-disable max-len */
import React from 'react';

export const ConatactRequestIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10C7 9.448 7.448 9 8 9C8.552 9 9 9.448 9 10C9 10.552 8.552 11 8 11C7.448 11 7 10.552 7 10ZM12 9C11.448 9 11 9.448 11 10C11 10.552 11.448 11 12 11C12.552 11 13 10.552 13 10C13 9.448 12.552 9 12 9ZM16 9C15.448 9 15 9.448 15 10C15 10.552 15.448 11 16 11C16.552 11 17 10.552 17 10C17 9.448 16.552 9 16 9ZM20 15C20 15.551 19.551 16 19 16H8.554C8.011 16 7.477 16.148 7.01 16.428L4 18.234V5C4 4.449 4.449 4 5 4H19C19.551 4 20 4.449 20 5V15ZM19 2H5C3.346 2 2 3.346 2 5V20C2 20.36 2.194 20.693 2.507 20.87C2.66 20.957 2.83 21 3 21C3.178 21 3.356 20.953 3.515 20.857L8.039 18.143C8.195 18.049 8.373 18 8.554 18H19C20.654 18 22 16.654 22 15V5C22 3.346 20.654 2 19 2Z"
      fill="#DF1721"
    />
  </svg>
);

export const OfferAddedIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 13H13V12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12V13H10C9.45 13 9 13.45 9 14C9 14.55 9.45 15 10 15H11V16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V15H14C14.55 15 15 14.55 15 14C15 13.45 14.55 13 14 13ZM20 18.0684C20 18.3064 19.776 18.5004 19.5 18.5004H4.5C4.224 18.5004 4 18.3064 4 18.0684V13.7554V5.9314C4 5.6934 4.224 5.5004 4.5 5.5004H8.626L11.226 8.6784C11.416 8.9114 11.7 9.0464 12 9.0464H19.5C19.776 9.0464 20 9.2394 20 9.4784V18.0684ZM19.5 7.0464H12.474L9.875 3.8674C9.685 3.6344 9.4 3.5004 9.101 3.5004H4.5C3.122 3.5004 2 4.5904 2 5.9314V18.0684C2 19.4094 3.122 20.5004 4.5 20.5004H19.5C20.878 20.5004 22 19.4094 22 18.0684V9.4784C22 8.1364 20.878 7.0464 19.5 7.0464Z"
      fill="#DF1721"
    />
  </svg>
);

export const ListingAddedIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9999 13H12.9999V12C12.9999 11.448 12.5529 11 11.9999 11C11.4469 11 10.9999 11.448 10.9999 12V13H9.99994C9.44694 13 8.99994 13.448 8.99994 14C8.99994 14.552 9.44694 15 9.99994 15H10.9999V16C10.9999 16.552 11.4469 17 11.9999 17C12.5529 17 12.9999 16.552 12.9999 16V15H13.9999C14.5529 15 14.9999 14.552 14.9999 14C14.9999 13.448 14.5529 13 13.9999 13ZM17.4442 20H6.55524C6.24924 20 6.00024 19.776 6.00024 19.5V4.5C6.00024 4.224 6.24924 4 6.55524 4H12.0002V7.15C12.0002 8.722 13.2172 10 14.7142 10H18.0002V19.5C18.0002 19.776 17.7512 20 17.4442 20ZM14.0002 4.978L16.7422 8H14.7142C14.3202 8 14.0002 7.619 14.0002 7.15V4.978ZM19.7402 8.328L14.2962 2.328C14.1062 2.119 13.8382 2 13.5552 2H6.55524C5.14624 2 4.00024 3.122 4.00024 4.5V19.5C4.00024 20.878 5.14624 22 6.55524 22H17.4442C18.8532 22 20.0002 20.878 20.0002 19.5V9C20.0002 8.751 19.9072 8.512 19.7402 8.328Z"
      fill="#DF1721"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="4"
      y="2"
      width="17"
      height="20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9999 13H12.9999V12C12.9999 11.448 12.5529 11 11.9999 11C11.4469 11 10.9999 11.448 10.9999 12V13H9.99994C9.44694 13 8.99994 13.448 8.99994 14C8.99994 14.552 9.44694 15 9.99994 15H10.9999V16C10.9999 16.552 11.4469 17 11.9999 17C12.5529 17 12.9999 16.552 12.9999 16V15H13.9999C14.5529 15 14.9999 14.552 14.9999 14C14.9999 13.448 14.5529 13 13.9999 13ZM17.4442 20H6.55524C6.24924 20 6.00024 19.776 6.00024 19.5V4.5C6.00024 4.224 6.24924 4 6.55524 4H12.0002V7.15C12.0002 8.722 13.2172 10 14.7142 10H18.0002V19.5C18.0002 19.776 17.7512 20 17.4442 20ZM14.0002 4.978L16.7422 8H14.7142C14.3202 8 14.0002 7.619 14.0002 7.15V4.978ZM19.7402 8.328L14.2962 2.328C14.1062 2.119 13.8382 2 13.5552 2H6.55524C5.14624 2 4.00024 3.122 4.00024 4.5V19.5C4.00024 20.878 5.14624 22 6.55524 22H17.4442C18.8532 22 20.0002 20.878 20.0002 19.5V9C20.0002 8.751 19.9072 8.512 19.7402 8.328Z"
        fill="white"
      />
    </mask>
  </svg>
);

export const ScheduleViewRequestIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11H13V8C13 7.447 12.552 7 12 7C11.448 7 11 7.447 11 8V12C11 12.553 11.448 13 12 13H16C16.553 13 17 12.553 17 12C17 11.447 16.553 11 16 11ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20ZM12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2Z"
      fill="#DF1721"
    />
  </svg>
);

export const UserIcon = props => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3181 4.87506C13.3181 7.35685 11.1557 9.37512 8.49671 9.37512C5.83769 9.37512 3.67528 7.35685 3.67528 4.87506C3.67528 2.39328 5.83769 0.375 8.49671 0.375C11.1557 0.375 13.3181 2.39328 13.3181 4.87506ZM16.9375 19.5C16.9375 20.121 16.3987 20.625 15.7321 20.625H1.26786C0.601295 20.625 0.0625 20.121 0.0625 19.5C0.0625 15.1574 3.84853 11.6249 8.5 11.6249C13.1515 11.6249 16.9375 15.1574 16.9375 19.5Z"
      fill="#DF1721"
    />
  </svg>
);
