import React, { createContext, useState, useEffect, useCallback, useRef, useMemo } from 'react';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import useDidUpdate from 'hooks/useDidUpdate';
import { DropdownOption } from 'components/Dropdown';
import { getListing } from 'api/listings';
import {
  getNotifications,
  reassignOffer as reassignOfferRequest,
  reassignListing as reassignListingRequest
} from './api';

interface ContextValues {
  notifications: Array<NotificationPageItem>;
  filter: Array<string>;
  setFilter: React.Dispatch<React.SetStateAction<Array<string>>>;
  filterOptions: Array<DropdownOption>;
  listing: Listing | undefined;
  setListing: React.Dispatch<React.SetStateAction<Listing | undefined>>;
  handleReassignUser: (value: number, notification: NotificationPageItem) => void;
  fetchListing: (value: string) => void;
  buyProcessId: string;
  setBuyProcessId: React.Dispatch<React.SetStateAction<string | undefined>>;
  totalPages: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const filterOptions: DropdownOption[] = [
  { value: 'contactRequest', text: 'Contact requests' },
  { value: 'scheduleRequest', text: 'Schedule viewing' },
  { value: 'createOffer', text: 'New offers' },
  { value: 'createListing', text: 'New listings' },
  { value: 'userSignedUp', text: 'New users' },
];

const NOTIFICATIONS_LIMIT = 20;

const NotificationsContext = createContext({} as ContextValues);

export const NotificationsContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<NotificationPageItem>>([]);
  const [listing, setListing] = useState<Listing>();
  const [buyProcessId, setBuyProcessId] = useState<string>();
  const [filter, setFilter] = useState<Array<string>>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const prevFilters = useRef<string | undefined>();
  const allFilters = useMemo(() => {
    const resetPage = prevFilters.current !== JSON.stringify(filter);
    return {
      limit: NOTIFICATIONS_LIMIT,
      type: (!filter.length || filter.length === filterOptions.length) ? undefined : filter.join(','),
      offset: resetPage ? 0 : currentPage
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filter), currentPage]);

  const fetchNotifications = useCallback(async (filters) => {
    try {
      const { notifications, totalPages } = await getNotifications(filters);
      setNotifications(notifications);
      setTotalPages(totalPages);
    } catch (err) {
      notify(tryGetFirstError(err.response) || err.message);
    }
  }, []);

  const reassignListing = values => {
    return async () => {
      try {
        await reassignListingRequest(values);
        notify('Listing reassigned successfully');
      } catch (err) {
        notify(tryGetFirstError(err.response) || err.message);
      }
    };
  };

  const reassignOffer = values => {
    return async () => {
      try {
        await reassignOfferRequest(values);
        notify('Offer reassigned successfully');
      } catch (err) {
        notify(tryGetFirstError(err.response) || err.message);
      }
    };
  };


  const handleReassignUser = async (userId: number, notification: NotificationPageItem) => {
    if (!notification) return;

    const { type } = notification;
    const requestHandler = type === 'createOffer' ? reassignOffer({
      userId,
      buyProcessId: notification.formData.buyProcessId
    }) : type === 'createListing' ? reassignListing({
      userId,
      listingId: notification.listingId!
    }) : null;

    if (!requestHandler) return;

    await requestHandler();
    fetchNotifications(allFilters);
  };

  const fetchListing = async (listingId: string) => {
    try {
      const listing = await getListing(listingId!);
      setListing(listing);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  useEffect(() => {
    fetchNotifications(allFilters);
  }, [fetchNotifications]);

  useEffect(() => {
    prevFilters.current = JSON.stringify(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filter)]);

  useDidUpdate(() => {
    fetchNotifications(allFilters);
  }, [fetchNotifications, allFilters]);

  const contextValues = {
    notifications,
    filter,
    setFilter,
    filterOptions,
    totalPages,
    listing,
    setListing,
    handleReassignUser,
    fetchListing,
    buyProcessId,
    setBuyProcessId,
    currentPage,
    setCurrentPage
  };

  return (
    <NotificationsContext.Provider value={contextValues}>{children}</NotificationsContext.Provider>
  );
};

export default NotificationsContext;
