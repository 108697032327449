import { useEffect, useState, useMemo } from 'react';

import { getUsers } from 'api/users';
import { mapUsers } from 'views/CalendarPage/mapping';
import { DropdownOption } from 'components';

export default () => {
  const [users, setUsers] = useState<Array<{ [key: string]: any; }>>([]);

  useEffect(() => {
    (async () => {
      try {
        const users = await getUsers();
        setUsers(users);
      } catch (err) {
        console.error(err.message);
      }
    })();
  }, []);

  const usersOptions: DropdownOption[] = useMemo(() => mapUsers(users), [users]);

  return {
    users,
    usersOptions,
  };
};
