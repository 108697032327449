/* eslint-disable camelcase */
import { fetchWithToken } from 'utils/fetch';
import queryString from 'query-string';
import keysToCamel from 'utils/keysToCamel';
import { ContactRequest } from 'views/RequestsPage/types.d';
import { mapContactRequest } from 'views/RequestsPage/api';
import { checkSuccess, urlEncodeBody } from 'utils/requests';

enum NotificationType {
  createOffer = 'New Offer added',
  reAssignOffer = 'You were assigned to the new offer',
  createListing = 'New Listing added',
  reAssignListing = 'You were assigned to the new listing',
  scheduleRequest = 'New schedule view request',
  contactRequest = 'New contact request',
  userSignedUp = 'User signed up',
  rejectServiceOrdered = 'Reject Service Ordered',
  cancelServiceOrdered = 'Cancel Service Ordered'
}

const getNotificationTitle = ({ type, created_for }) => {
  if (created_for && type === 'createListing') {
    return NotificationType.reAssignListing;
  }
  if (created_for && type === 'createOffer') {
    return NotificationType.reAssignOffer;
  }
  return NotificationType[type];
};

export async function getNotifications({
  offset = 0,
  limit = 0,
  type
}: {
  limit?: number;
  offset?: number;
  type?: string;
}): Promise<{
  notifications: Array<NotificationPageItem>;
  totalPages: number;
}> {
  const query = queryString.stringify({
    offset,
    limit,
    type
  });
  const response = await fetchWithToken(`/notifications?${query}`);
  const data = await response.json();

  checkSuccess(data);

  const { notifications: notificationsData } = data;

  const notifications = notificationsData.map(item => {
    const formData = typeof item.data === 'string' ? JSON.parse(item.data) : item.data;
    return {
      id: item.id,
      listingId: item.listing_id,
      requestId: item.request_id,
      type: item.type,
      date: item.created_on,
      createdFor: item.created_for,
      createdBy: item.created_by,
      title: getNotificationTitle(item),
      listingAddress:
        item.address ||
        [item.data.address1, item.address2, item.data.city, item.data.state, item.data.zip]
          .filter(Boolean)
          .join(', '),
      offerAmount: formData.offerAmount,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      reassignedTo: item.reassigned_to,
      reassignedToId: item.reassigned_to_id,
      reassignedToName: item.reassigned_to_name,
      formData: keysToCamel(formData)
    };
  });

  const totalPages = data.pagination.total_pages;

  return { notifications, totalPages };
}

export async function getRequestByNotificationId(id: number): Promise<ContactRequest> {
  const response = await fetchWithToken(`/requests/${id}`);
  const data = await response.json();

  return mapContactRequest(data);
}

export async function reassignOffer(values: {
  userId: number;
  buyProcessId: string;
}): Promise<void> {
  await fetchWithToken(`/offers/${values.buyProcessId}/reassign`, {
    method: 'PATCH',
    body: urlEncodeBody({ user_id: values.userId })
  });
}

export async function reassignListing(values: {
  userId: number;
  listingId: string;
}): Promise<void> {
  await fetchWithToken(`/listings/${values.listingId}/reassign`, {
    method: 'POST',
    body: urlEncodeBody({ user_id: values.userId })
  });
}
