import React, { useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';

import { Icon, Input, Button, DropdownOption } from 'components';
import useUsers from 'hooks/useUsers';

type UsersListProps = {
  assigned?: string;
  onAssignClick: (value: number) => void;
};

const UsersList: React.FC<UsersListProps> = ({ assigned, onAssignClick }) => {
  const { usersOptions } = useUsers();
  const [selectedUser, setSelectedUser] = useState<number | undefined>();
  const [search, setSearch] = useState<string>('');

  const filteredItems = useMemo(() => {
    const items = usersOptions.filter((option: DropdownOption) => option.value);
    if (search) return items.filter((option: DropdownOption) =>
      String(option.text).toLowerCase().includes(search.toLowerCase()));
    return items;
  }, [search, usersOptions]);

  useEffect(() => {
    setSelectedUser(undefined);
  }, [assigned]);

  return (
    <StyledUsersList className="users-list">
      <Input
        value={search}
        onChange={e => setSearch(e.target.value)}
        icon="search"
        iconPosition="left"
        placeholder="Search"
        className="users-search"
      />
      <ul className="users-options scrollable">
        {filteredItems.map(item => (
          <li
            key={item.value}
            onClick={() => setSelectedUser(item.value)}
            className="users-options__item">
            {item.value === selectedUser && <Icon name="checkmark" />}
            <span className="users-options__name">{item.text}</span>
            {item.search === assigned && <span className="assigned">ASSIGNED</span>}
          </li>
        ))}
      </ul>
      <Button onClick={() => onAssignClick(selectedUser!)} disabled={selectedUser === undefined}>
        Assign
      </Button>
    </StyledUsersList>
  );
};

export default React.memo(UsersList);

const StyledUsersList = styled.div`
  padding: 16px 0;

  .users-search {
    width: 100%;
    .input-component {
      height: 32px;
    }
  }

  .users-options {
    max-height: 160px;
    overflow: overlay;
    margin: 8px 0;
    padding: 0;
    list-style: none;
    &__item {
      display: flex;
      padding: 8px 8px 8px 0;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      .assigned {
        margin-left: auto;
        color: ${props => props.theme.colors.grayDark};
      }
      .icon-checkmark {
        fill: ${props => props.theme.colors.green};
        margin-right: 8px;
      }
    }
    &__name {
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .button {
    width: 100%;
    height: 40px;
  }
`;